#app-container {
    padding-top: 80px;
    min-height: 100vh;
}

* {
    font-family: Avenir, sans-serif;
}

body {
    overflow-x: hidden;
}
